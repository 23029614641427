:root {
  --spacePart: 15px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 15px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 35px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 35px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 20px;
  }
}
:root {
  --spaceTotal: 50px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 50px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 30px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #204e7b;
  line-height: 1.5;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #1b4167;
}
.button:active {
  background-color: #10283e;
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1024px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
div.sub1 {
  margin-left: 30px;
}
div.sub1 > .item {
  font-size: 18px;
  line-height: 1.33333333;
}
div.sub1 > .item:after {
  content: '|';
  float: left;
  margin: 0 5px;
}
div.sub1 > .item.exit:after {
  display: none;
}
div.sub1 > .item > .menu {
  float: left;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}
div.sub1 > .item > .menu.path {
  color: #204e7b;
  font-weight: bold;
}
@media (max-width: 1239px) {
  div.sub1 {
    margin-left: 20px;
  }
  div.sub1 > .item {
    font-size: 17px;
    line-height: 1.41176471;
  }
}
div.sub2 > .item {
  margin-right: 5px;
}
div.sub2 > .item.exit {
  margin-right: 0;
}
div.sub2 > .item > .menu {
  font-size: 18px;
  line-height: 1.33333333;
  padding: 11px 12px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  border-radius: 2px;
  border: 1px solid transparent;
  color: #204e7b;
}
div.sub2 > .item > .menu:hover,
div.sub2 > .item > .menu:focus,
div.sub2 > .item > .menu.path {
  border-color: #204e7b;
  color: #204e7b;
}
@media (max-width: 1239px) {
  div.sub2 > .item > .menu {
    font-size: 17px;
    line-height: 1.41176471;
    padding: 9px 10px;
  }
}
.section--two .subcontent--1 {
  width: 38.46153846%;
}
.section--two .subcontent--2 {
  float: right;
  width: 58.97435897%;
}
.section--one .area {
  width: 100%;
}
.section--one .area > .unit {
  margin-right: 1.25%;
  margin-left: 1.25%;
  width: 97.5%;
}
.section--one .area .cb-album .body,
.section--one .area .head,
.section--one .area .foot,
.section--one .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim {
  width: 47.5%;
}
.section--one .area > .slim .head,
.section--one .area > .slim .foot,
.section--one .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim .tiny {
  width: 100%;
}
.section--one .area {
  width: 102.56410256%;
  margin-left: -1.28205128%;
}
.section--one .area .pure.wide .part.tiny {
  width: 48.71794872%;
}
.section--one .area .seam {
  width: 47.5%;
}
.section--one .area .flat {
  width: 30.83333333%;
}
.section--one .area .edge {
  width: 22.5%;
}
/*# sourceMappingURL=./screen-large.css.map */